import React from 'react'
import { Badge, Container } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { kebabCase } from 'lodash'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Hero from '../../components/Hero'

export default function Template ({ data }) {
  const {
    site: { siteMetadata: { disqus } },
    markdownRemark: { html, excerpt, frontmatter: { title, subtitle, image, tags, comments } },
  } = data

  return (
    <Layout
      title={`${title} // blog`}
      keywords={tags}
      description={excerpt}
      topMargin={false}
    >
      <Helmet>
        {comments && disqus && (
          <script id='dsq-count-scr' src='//gatsby-starter-blog.disqus.com/count.js' async />
        )}
        {comments && disqus && (
          <script>{`(function() {
        var d = document, s = d.createElement('script');
        s.src = 'https://${disqus}.disqus.com/embed.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
        })();`}</script>
        )}
      </Helmet>

      <Hero size="small">
        <h1>{title}</h1>
        { subtitle ?
          (<h4>{subtitle}</h4>)
          : null
        }
      </Hero>
      <br/><br/>
      <Container className="dynamic-content">
        <div className="text-center">
          <img
            src={image}
            className="img-fluid"
            style={{ marginTop: '-175px' }}
            alt={title}
          />
        </div>
        <br/><br/><br/><br/>
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </Container>

      {tags && tags.length ? (
        <Container>
          <h4>Tags</h4>
          {tags.map(tag => (
            <Badge className="mr-1 mb-1" variant="primary" key={tag + `tag`}>
              <Link className="text-reset h6" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
            </Badge>
          ))}
        </Container>
      ) : null}

      {comments && disqus && (<Container>
        <hr />
        <div id='disqus_thread' />
      </Container>)}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($regex: String!) {
    site {
      siteMetadata {
        disqus
      }
    }

    markdownRemark(
      fileAbsolutePath: {regex: $regex}
    ) {
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        image
        tags
        comments
      }
    }
  }
`
